
import Vue from 'vue';
import Paginator from '@/common/components/paginator/Paginator.vue';

export default Vue.extend({
  name: 'GroupCardExpandableTable',
  components: {
    Paginator,
  },
  props: {
    headerLabels: {
      type: Array as () => { field: string; label: string }[],
      default: () => [],
    },
    tableData: {
      type: Array as () => Record<string, string | number>[],
      default: () => [],
    },
    expandableTotal: {
      type: Number as () => number,
      required: true,
    },
    paginatorLabel: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean as () => boolean,
      default: true,
    },
  },
  data() {
    return {
      orderBy: '',
    };
  },
  computed: {
    sortedList(): any[] {
      const data = this.tableData.slice();

      return data.sort((a, b) => {
        if (a[this.orderBy] < b[this.orderBy]) {
          return -1;
        }

        if (a[this.orderBy] > b[this.orderBy]) {
          return 1;
        }
        return 0;
      });
    },
  },
  methods: {
    handleOrderBy(field: string) {
      this.orderBy = field === this.orderBy ? '' : field;
    },
  },
});
