import BrazilGroupCard from '@/resources/my-companies/components/GroupCard/BrazilGroupCard.vue';
import Vue from 'vue';

const ForeignGroupCard = () => import('@/resources/my-companies/components/GroupCard/ForeignGroupCard.vue');
export default Vue.extend({
  name: 'GroupCardMixin',
  components: {
    BrazilGroupCard,
    ForeignGroupCard,
  },
  data() {
    return {
      countryGroupCard: {
        brazil: 'BrazilGroupCard',
      } as Record<string, string>,
      foreignGroupCard: 'ForeignGroupCard',
    };
  },
  methods: {
    getGroupCard(country: string): string {
      const groupCard = this.countryGroupCard[country.toLowerCase()];
      return groupCard || this.foreignGroupCard;
    },
  },
});
