var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("p", { staticClass: "description" }, [
        _vm._v(
          " " +
            _vm._s(_vm.$t("myCompanies.modals.createGroup.description")) +
            " "
        ),
      ]),
      _c("CreateGroupGroupDataForm", {
        ref: "createGroupGroupDataForm",
        attrs: { form: _vm.groupData, updating: !!_vm.group.name },
        on: {
          "update:form": function ($event) {
            _vm.groupData = $event
          },
          clearEstablishments: _vm.clearEstablishments,
        },
      }),
      _c("CreateGroupHeadOfficeDataForm", {
        attrs: { form: _vm.headOffice, country: _vm.groupData.country },
        on: {
          "update:form": function ($event) {
            _vm.headOffice = $event
          },
          addHeadOffice: _vm.addHeadOffice,
        },
      }),
      _vm.headOffices.length > 0
        ? _c("CreateGroupHeadOfficeTable", {
            attrs: { rows: _vm.headOffices, country: _vm.groupData.country },
            on: {
              removeHeadOffice: function ($event) {
                return _vm.removeHeadOffice($event)
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }