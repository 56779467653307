import { CreateCompaniesDTO, CreateCorporateDTO } from '@/resources/my-companies/Types';
import axios from 'axios';

class CorporatesService {
  private service: typeof axios = axios;

  private baseURL = `${process.env.VUE_APP_REBORN_BASE_URL_GATEWAY}/api/v1/corporates`;

  async create(dto: CreateCorporateDTO): Promise<void> {
    await this.service.post(this.baseURL, dto);
  }

  async createCompanyInBatch(dto: CreateCompaniesDTO): Promise<void> {
    await this.service.post(`${this.baseURL}/companies`, dto);
  }
}

export default new CorporatesService();
