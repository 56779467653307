var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "table-container" },
    [
      _c("table", { staticClass: "table-container__table" }, [
        _c("thead", { staticClass: "table-container__header header" }, [
          _c(
            "tr",
            { staticClass: "header-expandable__row" },
            [
              _c("th", { staticClass: "tree" }),
              _vm._l(_vm.headerLabels, function (column) {
                return [
                  _vm._t(
                    "header",
                    function () {
                      return [
                        _c("th", { key: column.label }, [
                          _c("div", { staticClass: "header__label" }, [
                            _vm._v(" " + _vm._s(column.label) + " "),
                            column.field
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "header__sort",
                                    class: {
                                      "header__sort--highlight":
                                        _vm.orderBy === column.field,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleOrderBy(column.field)
                                      },
                                    },
                                  },
                                  [_c("i", { staticClass: "icon-r-sort-1" })]
                                )
                              : _vm._e(),
                          ]),
                        ]),
                      ]
                    },
                    { column: column }
                  ),
                ]
              }),
            ],
            2
          ),
        ]),
        _c(
          "tbody",
          { staticClass: "table-container__body body cel-expandable" },
          [
            _vm.loading
              ? _vm._l(10, function (index) {
                  return _c(
                    "tr",
                    {
                      key: `loading-${index}`,
                      staticClass: "body-expandable__row--loading",
                    },
                    [
                      _c("td", { staticClass: "tree" }),
                      _vm._l(_vm.headerLabels, function (item, columnIndex) {
                        return _c(
                          "td",
                          { key: `${item}-${index}-${columnIndex}` },
                          [_c("p", [_vm._v(" " + _vm._s(item) + " ")])]
                        )
                      }),
                    ],
                    2
                  )
                })
              : _vm._l(_vm.sortedList, function (item, idx) {
                  return _c(
                    "tr",
                    {
                      key: `row-expandable-${idx}`,
                      staticClass: "body-expandable__row",
                    },
                    [
                      _c("td", { staticClass: "tree" }),
                      _vm._t("body", null, { expandableItens: item }),
                    ],
                    2
                  )
                }),
          ],
          2
        ),
      ]),
      _c("Paginator", {
        staticClass: "paginator",
        attrs: {
          "item-label": _vm.paginatorLabel,
          "total-items": _vm.expandableTotal,
        },
        on: {
          changePageSize: function ($event) {
            return _vm.$emit("changePageSize", $event)
          },
          goToLastPage: function ($event) {
            return _vm.$emit("goToLastPage")
          },
          goToFirstPage: function ($event) {
            return _vm.$emit("goToFirstPage")
          },
          nextPage: function ($event) {
            return _vm.$emit("nextPage")
          },
          previousPage: function ($event) {
            return _vm.$emit("previousPage")
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }