var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "ValidationObserver",
    { ref: "form", attrs: { slim: "" } },
    [
      _c(
        "EditDataInTable",
        {
          ref: "editData",
          on: { saveChanges: ($event) => _vm.saveChanges($event) },
        },
        [
          _c("InputValidation", {
            attrs: { rules: _vm.rules, mode: "lazy" },
            scopedSlots: _vm._u([
              {
                key: "input",
                fn: function ({ errors }) {
                  return [
                    _c(
                      "div",
                      { staticClass: "input-container" },
                      [
                        _c("label", { attrs: { for: "inputText" } }, [
                          _vm._v(_vm._s(_vm.label)),
                        ]),
                        _c(
                          "el-select",
                          {
                            class: { "p-invalid": errors[0] },
                            attrs: {
                              id: "retreat",
                              placeholder: _vm.$t("select"),
                            },
                            model: {
                              value: _vm.valueSelected,
                              callback: function ($$v) {
                                _vm.valueSelected = $$v
                              },
                              expression: "valueSelected",
                            },
                          },
                          _vm._l(_vm.options, function (option, i) {
                            return _c("el-option", {
                              key: i,
                              attrs: {
                                label: option.name,
                                value: option.value,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }