import Vue from 'vue';

export default Vue.extend({
  name: 'TaxMixin',
  components: {
  },
  data() {
    return {
      tributaryRegime: [
        { name: 'SIMPLES', value: 'SIMPLES' },
        { name: 'PRESUMIDO', value: 'PRESUMIDO' },
        { name: 'REAL', value: 'REAL' },
        { name: 'ARBITRADO', value: 'ARBITRADO' },
        { name: 'ISENTO', value: 'ISENTO' },
        { name: 'IMUNE', value: 'IMUNE' },
        { name: 'PESSOA FÍSICA', value: 'naturalPerson' },
      ] as Array<{ name: string; value: string }>,
      polling: [
        { name: 'COMPETÊNCIA', value: 'COMPETENCIA' },
        { name: 'ESTIMATIVA', value: 'ESTIMATIVA' },
        { name: 'CAIXA', value: 'CAIXA' },
        { name: 'TRIMESTRAL', value: 'TRIMESTRAL' },
        { name: 'ANUAL', value: 'ANUAL' },
        { name: 'OUTROS', value: 'OUTROS' },
        { name: 'PESSOA FÍSICA', value: 'naturalPerson' },
      ] as Array<{ name: string; value: string }>,
      retreat: [
        { name: 'TRIBUTO E DOCUMENTO', value: 'document' },
        { name: 'TRIBUTO E FORNECEDOR', value: 'provider' },
        { name: 'ACUMULADO POR TRIBUTO', value: 'tax' },
        { name: 'PESSOA FÍSICA', value: 'naturalPerson' },
      ] as Array<{ name: string; value: string }>,
    };
  },
  methods: {
    getTributaryRegime(value: string) {
      const data = this.tributaryRegime.find((d) => d.value === value);
      return data?.name || '';
    },
    getPolling(value: string) {
      const data = this.polling.find((d) => d.value === value);
      return data?.name || '';
    },
    getRetreat(value: string) {
      const data = this.retreat.find((d) => d.value === value);
      return data?.name || '';
    },
  },
});
