var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(_vm.getGroupCard(_vm.group.country), {
    tag: "component",
    attrs: { group: _vm.group, "expandable-loading": _vm.expandableLoading },
    on: {
      updateExpandableLoad: ($event) => _vm.updateExpandableLoad($event),
      registerGroup: function ($event) {
        return _vm.$emit("registerGroup")
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }