
// eslint-disable-next-line import/extensions
import OverlayPanel from 'primevue/overlaypanel/OverlayPanel';
import Vue from 'vue';

export default Vue.extend({
  name: 'FilterTable',
  props: {
    options: {
      type: Array as () => Array<Record<string, unknown>>,
      required: true,
    },
  },
  methods: {
    toggleOp(event: Event) {
      (this.$refs.op as OverlayPanel).toggle(event);
    },
    handleSave(event: Event) {
      this.$emit('saveChanges', event);
    },
  },
});
