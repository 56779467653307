var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { on: { click: _vm.toggleOp } },
    [
      _c("i", { staticClass: "icon-r-filter" }),
      _c(
        "OverlayPanel",
        {
          ref: "op",
          staticClass: "op",
          attrs: {
            dismissable: false,
            "append-to": "body",
            "show-close-icon": true,
          },
        },
        [
          _c("MultiSelect", {
            attrs: {
              placeholder: _vm.$t(
                "integrations.form.establishment.placeholder"
              ),
              display: "chip",
              options: _vm.options,
              "option-value": "value",
              "option-label": "name",
              filter: true,
            },
          }),
          _c(
            "div",
            { staticClass: "edit-data__container-buttons" },
            [
              _c("Button", {
                staticClass: "outlined",
                attrs: { id: "cancelButton", label: _vm.$t("cancel") },
                on: { click: _vm.toggleOp },
              }),
              _c("Button", {
                staticClass: "raised",
                attrs: { id: "registerButton", label: _vm.$t("save") },
                on: { click: _vm.handleSave },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }