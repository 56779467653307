var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.totalItems > 10
    ? _c(
        "nav",
        {
          staticClass: "rds-table-paginator",
          attrs: {
            role: "navigation",
            "aria-label": _vm.$t("wca.components.pagination"),
            "data-cy": "paginator",
          },
        },
        [
          _c(
            "div",
            { staticClass: "page-size" },
            [
              _c("Dropdown", {
                attrs: {
                  options: _vm.pageSizeOptions,
                  "option-label": "value",
                  "option-value": "value",
                  disabled: _vm.lastPage === 0,
                },
                on: { change: _vm.changePageSize },
                model: {
                  value: _vm.selectedPageSize,
                  callback: function ($$v) {
                    _vm.selectedPageSize = $$v
                  },
                  expression: "selectedPageSize",
                },
              }),
              _c("p", [
                _vm._v(
                  " Exibindo " +
                    _vm._s((_vm.actualPage - 1) * +_vm.selectedPageSize + 1) +
                    " a " +
                    _vm._s(_vm.itemsDisplayed) +
                    " de " +
                    _vm._s(_vm.totalItems) +
                    " " +
                    _vm._s(_vm.itemLabel) +
                    " "
                ),
              ]),
            ],
            1
          ),
          _c("div", { staticClass: "paginator" }, [
            _c("div", { staticClass: "prev-side" }, [
              _c(
                "button",
                {
                  staticClass: "double-chevron",
                  class: { disabled: _vm.actualPage === 1 },
                  attrs: { disabled: _vm.actualPage === 1 },
                  on: { click: _vm.goToFirstPage },
                },
                [
                  _c("i", {
                    staticClass: "icon-r-chevron-outline-double-left icon-size",
                  }),
                ]
              ),
              _c(
                "button",
                {
                  staticClass: "double-chevron",
                  class: { disabled: _vm.actualPage === 1 },
                  attrs: { disabled: _vm.actualPage === 1 },
                  on: { click: _vm.previousPage },
                },
                [
                  _c("i", {
                    staticClass: "icon-r-chevron-outline-left icon-size",
                  }),
                ]
              ),
            ]),
            _c("div", { staticClass: "counter-info" }, [
              _c("p", { staticClass: "counter-item" }, [
                _vm._v(" " + _vm._s(_vm.actualPage) + " "),
              ]),
              _c("p", { staticClass: "counter-item" }, [_vm._v(" de ")]),
              _c("p", { staticClass: "counter-item" }, [
                _vm._v(" " + _vm._s(_vm.lastPage) + " "),
              ]),
            ]),
            _c("div", { staticClass: "next-side" }, [
              _c(
                "button",
                {
                  staticClass: "double-chevron",
                  class: { disabled: _vm.actualPage === _vm.lastPage },
                  attrs: {
                    disabled: _vm.actualPage === _vm.lastPage || _vm.isLoading,
                  },
                  on: { click: _vm.nextPage },
                },
                [
                  _c("i", {
                    staticClass: "icon-r-chevron-outline-right icon-size",
                  }),
                ]
              ),
              _c(
                "button",
                {
                  staticClass: "double-chevron",
                  class: { disabled: _vm.actualPage === _vm.lastPage },
                  attrs: {
                    disabled: _vm.actualPage === _vm.lastPage || _vm.isLoading,
                  },
                  on: { click: _vm.goToLastPage },
                },
                [
                  _c("i", {
                    staticClass:
                      "icon-r-chevron-outline-double-right icon-size",
                  }),
                ]
              ),
            ]),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }