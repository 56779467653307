var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("ValidationObserver", { ref: "form", attrs: { slim: "" } }, [
    _c("form", { staticClass: "form" }, [
      _c("fieldset", { staticClass: "form__fieldset" }, [
        _c("legend", { staticClass: "form__title" }, [
          _vm._v(
            " " +
              _vm._s(_vm.$t("myCompanies.modals.createGroup.groupData")) +
              " "
          ),
        ]),
        _c("div", { staticClass: "inputs-container" }, [
          _c(
            "div",
            { staticClass: "inputs-container__container" },
            [
              _c("label", { attrs: { for: "groupName" } }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("myCompanies.modals.createGroup.groupName")) +
                    " "
                ),
              ]),
              _c("InputValidation", {
                attrs: { rules: "required" },
                scopedSlots: _vm._u([
                  {
                    key: "input",
                    fn: function ({ errors }) {
                      return [
                        _c("InputText", {
                          staticClass: "inputs-container__input",
                          class: { "p-invalid": errors[0] },
                          attrs: {
                            id: "groupName",
                            disabled: _vm.updating,
                            placeholder: _vm.$t(
                              "myCompanies.modals.createGroup.groupName"
                            ),
                          },
                          model: {
                            value: _vm.form.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "name", $$v)
                            },
                            expression: "form.name",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "inputs-container__container" },
            [
              _c("label", { attrs: { for: "country" } }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("myCompanies.modals.createGroup.countryFrom")
                    ) +
                    " "
                ),
              ]),
              _c(
                "el-select",
                {
                  staticClass: "inputs-container__input",
                  attrs: {
                    id: "country",
                    loading: _vm.countries.length === 0,
                    placeholder: "loading",
                    disabled: _vm.updating,
                    filterable: "",
                    "no-match-text": "Sem dados encontrados",
                    "no-data-text": "Sem dados",
                    "loading-text": "Carregando...",
                  },
                  on: {
                    change: function ($event) {
                      return _vm.$emit("clearEstablishments")
                    },
                  },
                  model: {
                    value: _vm.form.country,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "country", $$v)
                    },
                    expression: "form.country",
                  },
                },
                _vm._l(_vm.countries, function (option) {
                  return _c(
                    "el-option",
                    {
                      key: _vm.normalize(option.name),
                      attrs: {
                        label: _vm.getNameInCurrentLanguague(option),
                        value: option.name,
                      },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "align-items": "center",
                          },
                        },
                        [
                          _c("img", {
                            staticStyle: {
                              width: "16px",
                              heigth: "10px",
                              "margin-right": "8px",
                            },
                            attrs: { src: option.flag },
                          }),
                          _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(_vm.getNameInCurrentLanguague(option)) +
                                " "
                            ),
                          ]),
                        ]
                      ),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }