var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "Dialog",
    {
      staticClass: "confirm-modal",
      style: { maxHeight: "none", minWidth: "644px", padding: "0 16px" },
      attrs: {
        visible: _vm.displayModal,
        modal: true,
        "content-style": { overflow: "visible" },
      },
      on: {
        "update:visible": function ($event) {
          _vm.displayModal = $event
        },
      },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function () {
            return [
              _c(
                "h1",
                { staticClass: "m-0 fs16 family-semi-bold color-black87" },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("myCompanies.modals.confirmCreateGroup.header")
                      ) +
                      " "
                  ),
                ]
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "footer",
          fn: function () {
            return [
              _c("Button", {
                staticClass: "outlined",
                attrs: {
                  label: _vm.$t(
                    "myCompanies.modals.confirmCreateGroup.correct"
                  ),
                },
                on: {
                  click: function ($event) {
                    _vm.displayModal = false
                  },
                },
              }),
              _c("Button", {
                attrs: { label: _vm.$t("confirm") },
                on: { click: _vm.confirm },
              }),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      [
        _c("p", { staticClass: "confirm-modal__description" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t("myCompanies.modals.confirmCreateGroup.description")
              ) +
              " "
          ),
        ]),
        _c("table", { staticClass: "confirm-modal-table" }, [
          _c("thead", [
            _c("tr", [
              _c("th", [
                _vm._v(
                  _vm._s(
                    _vm.$t("myCompanies.modals.confirmCreateGroup.groupName")
                  )
                ),
              ]),
              _c("th", [
                _vm._v(
                  _vm._s(
                    _vm.$t("myCompanies.modals.confirmCreateGroup.countryFrom")
                  )
                ),
              ]),
            ]),
          ]),
          _c("tbody", [
            _c("tr", [
              _c("td", [_vm._v(_vm._s(_vm.groupData.name))]),
              _c("td", [
                _c("div", [
                  _c("img", { attrs: { src: _vm.flag, alt: "bandeira" } }),
                  _vm._v(" " + _vm._s(_vm.country) + " "),
                ]),
              ]),
            ]),
          ]),
        ]),
        _c("p", { staticClass: "confirm-modal__warning-msg" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t("myCompanies.modals.confirmCreateGroup.warningMsg")
              ) +
              " "
          ),
        ]),
      ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }