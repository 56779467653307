
import mixins from 'vue-typed-mixins';
import CreateGroupHeadOfficeFormMixin from '@/resources/my-companies/components/CreateGroupHeadOfficeDataForms/CreateGroupHeadOfficeFormMixin';
import { FormRef } from './FormMixin';
import { CompanyInfo } from '../../Types';

export default mixins(CreateGroupHeadOfficeFormMixin).extend({
  name: 'CreateGroupHeadOfficeDataForm',
  props: {
    form: {
      type: Object as () => CompanyInfo,
      required: true,
    },
    country: {
      type: String as () => string,
      required: true,
    },
  },
  data() {
    return {
      localForm: {} as CompanyInfo,
    };
  },
  watch: {
    localForm: {
      deep: true,
      handler(data) {
        this.$emit('update:form', data);
      },
    },
  },
  methods: {
    async addHeadOffice() {
      const form = this.$refs.form as FormRef;
      const isValid = await form.validForm();

      if (isValid) {
        this.$emit('addHeadOffice');
        form.clearValidations();
        form.clearForm();
        this.localForm = {} as CompanyInfo;
      }
    },
  },

});
