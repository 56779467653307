var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("PageCard", {
    staticClass: "my-companies-page",
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [
            _c("HeaderSection", {
              on: {
                "company:show": function ($event) {
                  _vm.showCompanyGroupModal = $event
                },
                onSearch: function ($event) {
                  return _vm.searchGroup($event)
                },
              },
            }),
            _c("CompanyGroupProcessingWarning", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showProcessingWarning,
                  expression: "showProcessingWarning",
                },
              ],
              staticClass: "mt-3",
              attrs: { "operation-title": "register" },
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "content",
        fn: function () {
          return [
            _c("TableLoading", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.tableLoading,
                  expression: "tableLoading",
                },
              ],
            }),
            !_vm.tableLoading && _vm.groupsData.length === 0
              ? _c("CompanyGroupNotFound")
              : _vm._e(),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.tableLoading,
                    expression: "!tableLoading",
                  },
                ],
              },
              _vm._l(_vm.groupsData, function (group, idx) {
                return _c("GroupCard", {
                  key: `${group.name}-${idx}`,
                  attrs: { group: group },
                  on: {
                    registerGroup: function ($event) {
                      _vm.showProcessingWarning = true
                    },
                  },
                })
              }),
              1
            ),
            _c("CreateGroupModal", {
              attrs: { control: _vm.showCompanyGroupModal },
              on: {
                "update:control": function ($event) {
                  _vm.showCompanyGroupModal = $event
                },
                registerGroup: function ($event) {
                  _vm.showProcessingWarning = true
                },
              },
            }),
            _c("Paginator", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isSearchingGroup,
                  expression: "!isSearchingGroup",
                },
              ],
              staticClass: "paginator",
              attrs: {
                "item-label": _vm.$t("myCompanies.paginatorLabel"),
                "total-items": _vm.groupsTotal,
              },
              on: {
                changePageSize: _vm.changePageSize,
                goToLastPage: _vm.goToLastPage,
                goToFirstPage: _vm.goToFirstPage,
                nextPage: _vm.nextPage,
                previousPage: _vm.previousPage,
              },
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }