
import mixins from 'vue-typed-mixins';
import FormMixin from '@/resources/my-companies/components/CreateGroupHeadOfficeDataForms/FormMixin';
import {
  ICity, IState, State, City,
} from 'country-state-city';
import { Mask } from 'maska';
import { BrazilForm } from './types';

export default mixins(FormMixin).extend({
  name: 'BrazilForm',
  data() {
    return {
      tributaryRegime: [
        { name: 'SIMPLES', value: 'SIMPLES' },
        { name: 'PRESUMIDO', value: 'PRESUMIDO' },
        { name: 'REAL', value: 'REAL' },
        { name: 'ARBITRADO', value: 'ARBITRADO' },
        { name: 'ISENTO', value: 'ISENTO' },
        { name: 'IMUNE', value: 'IMUNE' },
      ] as Array<{ name: string; value: string }>,
      polling: [
        { name: 'COMPETÊNCIA', value: 'COMPETENCIA' },
        { name: 'ESTIMATIVA', value: 'ESTIMATIVA' },
        { name: 'CAIXA', value: 'CAIXA' },
        { name: 'TRIMESTRAL', value: 'TRIMESTRAL' },
        { name: 'ANUAL', value: 'ANUAL' },
        { name: 'OUTROS', value: 'OUTROS' },
      ] as Array<{ name: string; value: string }>,
      retreat: [
        { name: 'TRIBUTO E DOCUMENTO', value: 'document' },
        { name: 'TRIBUTO E FORNECEDOR', value: 'provider' },
        { name: 'ACUMULADO POR TRIBUTO', value: 'tax' },
      ] as Array<{ name: string; value: string }>,
      states: [] as IState[],
      cities: [] as ICity[],
      localForm: {
        establishmentType: 'CNPJ',
      } as BrazilForm,
    };
  },
  async mounted() {
    this.states = State.getStatesOfCountry('BR');
  },
  methods: {
    async getCity(stateName: string) {
      this.cities = [];
      this.localForm = { ...this.localForm, city: '' };
      this.$emit('update:form', this.localForm);
      const state = this.states.find((value) => value.name === stateName);
      this.cities = City.getCitiesOfState('BR', state?.isoCode as string);
    },
    handleNaturalPerson() {
      this.localForm = {
        establishmentType: 'CPF',
      } as BrazilForm;
      this.setTaxToNaturalPerson();
      this.clearValidations();
    },
    handleArtificialPerson() {
      this.localForm = {
        establishmentType: 'CNPJ',
      } as BrazilForm;
      this.clearValidations();
    },
    setTaxToNaturalPerson() {
      this.localForm.taxRegime = 'naturalPerson';
      this.localForm.retreat = 'naturalPerson';
      this.localForm.polling = 'naturalPerson';
    },
    clearForm() {
      this.localForm = {
        establishmentType: this.localForm.establishmentType,
      } as BrazilForm;

      if (this.localForm.establishmentType === 'CPF') {
        this.setTaxToNaturalPerson();
      }
      this.cities.splice(0);
    },
    onlyLetter() {
      this.localForm.name = this.localForm.name.replaceAll(/[^a-zA-Z\s]/g, '').trimStart();
    },
  },
  watch: {
    localForm: {
      deep: true,
      handler() {
        const maskNationalRegister = this.localForm.establishmentType === 'CNPJ' ? '##.###.###/####-##' : '###.###.###-##';
        const mask = new Mask({ mask: maskNationalRegister });
        this.$emit('update:form', { ...this.localForm, nationalRegister: mask.unmasked(this.localForm.nationalRegister || '') });
      },
    },
  },
});
