import BrazilTable from '@/resources/my-companies/components/CreateGroupHeadOfficeTables/BrazilTable.vue';
import Vue from 'vue';

const ForeignEstablishmentTable = () => import('@/resources/my-companies/components/CreateGroupHeadOfficeTables/ForeignEstablishmentTable.vue');

export default Vue.extend({
  name: 'CreateGroupHeadOfficeTableMixin',
  components: {
    BrazilTable,
    ForeignEstablishmentTable,
  },
  data() {
    return {
      countryTables: {
        brazil: 'BrazilTable',
      } as Record<string, string>,
      foreignEstablishmentTable: 'ForeignEstablishmentTable',
    };
  },
  methods: {
    getTable(country: string): string {
      const table = this.countryTables[country.toLowerCase()];
      return table || this.foreignEstablishmentTable;
    },
  },
});
