var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(_vm.getTable(_vm.country), {
    tag: "component",
    staticClass: "create-group-table",
    attrs: { rows: _vm.rows },
    scopedSlots: _vm._u([
      {
        key: "actions",
        fn: function (item) {
          return [
            _c("div", { staticClass: "actions" }, [
              _c(
                "button",
                {
                  staticClass: "action border-none",
                  on: {
                    click: function ($event) {
                      return _vm.$emit("removeHeadOffice", item)
                    },
                  },
                },
                [
                  _c("i", {
                    directives: [
                      {
                        name: "b-tooltip",
                        rawName: "v-b-tooltip.top.html",
                        value: _vm.$t("delete"),
                        expression: "$t('delete')",
                        modifiers: { top: true, html: true },
                      },
                    ],
                    staticClass: "icon-r-trash",
                  }),
                ]
              ),
            ]),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }