
import Vue from 'vue';
import EditDataInTable from '@/resources/my-companies/components/EditDataInTable.vue';
import InputValidation from '@/common/components/input-validation/InputValidationV2.vue';
import { VForm } from '@/types';

export type EditDataRef = InstanceType<typeof EditDataInTable>

export default Vue.extend({
  name: 'EditDataInTableInput',
  components: {
    EditDataInTable,
    InputValidation,
  },
  props: {
    label: {
      type: String as () => string,
      required: true,
    },
    inputType: {
      type: String as () => 'numeric',
      required: false,
      default: undefined,
    },
    rules: {
      type: String as () => string,
      required: false,
      default: 'required',
    },
    input: {
      type: String as () => string,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      inputText: this.input !== 'notInformed' ? this.input : '',
    };
  },
  methods: {
    async validForm(): Promise<boolean> {
      const form = this.$refs.form as VForm;
      return form.validate();
    },
    async saveChanges(event: Event) {
      if (await this.validForm()) {
        this.$emit('saveChanges', this.inputText);
        this.$emit('update:input', this.inputText);

        (this.$refs.editData as EditDataRef).toggleOp(event);
      }
    },
    handleInput(inputValue: string) {
      const sanitizeRegexs = {
        numeric: /[^0-9]/g,
      };
      const sanitizeRegex = sanitizeRegexs[this.inputType] || '';
      this.inputText = inputValue.replaceAll(sanitizeRegex, '');
    },
  },
});
