
import Vue from 'vue';

export default Vue.extend({
  name: 'InputValidationV2',
  props: {
    rules: {
      type: String as () => string,
      required: false,
      default: 'required',
    },
    mode: {
      type: String as () => string,
      default: 'aggressive',
    },
  },
});
