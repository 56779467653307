import BrazilForm from '@/resources/my-companies/components/CreateGroupHeadOfficeDataForms/BrazilForm.vue';
import Vue from 'vue';

const ForeignEstablishmentForm = () => import('@/resources/my-companies/components/CreateGroupHeadOfficeDataForms/ForeignEstablishmentForm.vue');

export default Vue.extend({
  name: 'CreateGroupHeadOfficeFormMixin',
  components: {
    BrazilForm,
    ForeignEstablishmentForm,
  },
  data() {
    return {
      countryForms: {
        brazil: 'BrazilForm',
      } as Record<string, string>,
      foreignEstablishmentForm: 'ForeignEstablishmentForm',
    };
  },
  methods: {
    getForm(country: string): string {
      const form = this.countryForms[country.toLowerCase()];
      return form || this.foreignEstablishmentForm;
    },
  },
});
