import axios from '@/common/http';
import { CountryOptions } from '@/resources/my-companies/Types';

class CountriesService {
  private baseUrl = 'https://restcountries.com/v2';

  private service: typeof axios = axios;

  async getAll(fields?: string[]): Promise<CountryOptions[]> {
    const url = new URL(`${this.baseUrl}/all`);
    if (fields) {
      url.searchParams.append('fields', fields.join(','));
    }
    const result = await this.service.get(url.toString());
    return result.data;
  }

  async getByFullName(fullName: string, fields?: string[]): Promise<CountryOptions[]> {
    const params = new URLSearchParams();
    params.set('fields', fields ? fields.join(',') : '');

    const url = new URL(`${this.baseUrl}/name/${fullName}?fullText=true`);

    const result = await this.service.get(url.toString(), { params });
    return result.data;
  }
}

export default new CountriesService();
