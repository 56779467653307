
import { Establishment } from '@roit-intern/roit-company-interfaces';
import StatusCompanyTable from '@/common/components/company/table/StatusCompanyTable.vue';
import numberRemoveFormat from '@/common/filters/NumberRemoveFormat';
import EstablishmentService from '@/common/services/EstablishmentService';
import PageCard from '@/common/template/PageCard.vue';
import CompanyService from '@/common/services/CompanyService';
import { HttpErrorResponse, PaginationMetadata } from '@/types';
import mixins from 'vue-typed-mixins';
import NotificationMixin from '@/mixins/NotificationMixin';
import CompanyGroupMixin from '@/mixins/CompanyGroupMixin';

export default mixins(NotificationMixin, CompanyGroupMixin).extend({

  name: 'EditableCompanyTable',

  components: {
    StatusCompanyTable,
    PageCard,
  },

  data() {
    return {
      tableKey: 0,
      rowsData: [] as Establishment[],
      allItemsSelected: false as boolean,
      selectedItems: [] as Array<string>,
      loading: false,
      page: 0,
      perPage: 10,
      rowsDataTotalLength: 0,
      collapsed: [] as string[],
      initialSort: 'nationalRegister',
      header: {
        alias: [
          this.$t('registerCompanyTable.headers.cnpj'),
          this.$t('registerCompanyTable.headers.razaoSocial'),
          this.$t('registerCompanyTable.headers.city'),
          this.$t('registerCompanyTable.headers.uf'),
          this.$t('registerCompanyTable.headers.status'),
          this.$t('registerCompanyTable.headers.ie'),
          this.$t('registerCompanyTable.headers.im'),
        ],
        columnsToShow: [
          'nationalRegister',
          'companyName',
          'address.city',
          'address.state',
          'legalSituation',
          'stateRegistration',
          'municipalRegistration',
        ],
      },
    };
  },
  async mounted() {
    await this.paginateBranchs();
  },
  methods: {
    editCompany(company: Establishment): void {
      this.updateCompany({
        ...company,
        stateRegistration: numberRemoveFormat(company.stateRegistration),
      });
    },

    getStatusActive(status: string): boolean {
      if (['ativa', 'ativo'].includes(status?.toLowerCase())) {
        return true;
      }

      return false;
    },
    async paginateBranchs() {
      this.loading = true;

      const data = await EstablishmentService.findByCompanyId(
        this.$route.params.companyId,
        true,
        { page: this.page, perPage: this.perPage, serializer: 'my-companies' },
      ) as unknown as {establishments: Establishment[]; metadata: PaginationMetadata};

      this.rowsData = data.establishments.filter((estab) => !estab.headOffice);
      this.rowsDataTotalLength = (data.metadata as {totalItens: number}).totalItens;
      this.loading = false;
    },
    getTypedBranchs(
      establishments: Establishment & {branchs: Establishment[]},
    ): Establishment[] {
      return establishments.branchs.filter((branch) => branch.id);
    },
    async updateCurrentPage(currentPage: number) {
      this.loading = true;
      this.page = currentPage - 1;
      await this.paginateBranchs();
      this.loading = false;
    },
    async updatePageSize(pageSize: number) {
      this.loading = true;
      this.perPage = pageSize;
      this.page = 0;
      await this.paginateBranchs();
      this.loading = false;
    },
    async backToCompanies() {
      this.$router.push('my-companies');
    },
    async updateCompany(company: any): Promise<void> {
      try {
        const payloadEstablishment = {
          nationalRegister: company.nationalRegister.replace(/\D/g, ''),
          stateRegistration: company?.stateRegistration || '',
          municipalRegistration: company?.municipalRegistration || '',
        };
        await EstablishmentService.updateEstablishment(payloadEstablishment);

        if (company?.company?.taxRegime?.type) {
          const payloadCompany = {
            companyId: company.companyId,
            taxRegime: {
              assessment: company.company.taxRegime.assessment,
              type: company.company.taxRegime.type,
            },
          };
          await CompanyService.addTaxRegime(payloadCompany);
        }

        if (company?.company?.taxGuide) {
          const payloadCompany = {
            companyId: company.companyId,
            taxGuide: company.company.taxGuide,
          };
          await CompanyService.addTaxGuide(payloadCompany);
        }

        this.successToast({
          text: this.$t('onboarding.registerCompany.table.successUpdate') as string,
        });
      } catch (error) {
        if (!(error instanceof TypeError)) {
          const e = error as HttpErrorResponse;
          this.handleErrors(e);
          throw new Error(e.data.message);
        }
      }
    },
  },
});
