export interface Group {
  id: string;
  title: string;
  companies: Company[];
}

export interface Company {
  id: number;
  cnpj: string;
  razaoSocial: string;
  city: string;
  uf: string;
  status: string;
  ie: string;
  im: string;
  rt: string;
  erpId: string;
  children: Company[];
  isChild?: boolean;
  parentId?: number;
  isLastChild?: boolean;
  stateRegistration: string;
}

export interface CountryOptions {
  name: string;
  translations: Record<string, string>;
  flag: string;
}

export interface CompanyInfo {
  nationalRegister: string;
  retreat: string;
  polling: string;
  taxRegime: string;
  establishmentType: string;
  state?: string;
  city?: string;
}

export interface CreateCorporateDTO {
  masterId: string;
  name: string;
  country: string;
  companiesInfo: CompanyInfo[];
}

export interface CreateCompaniesDTO {
  companies: Array<CompanyInfo & { companyGroupId: string }>;
}

export interface GroupInfo {
  name: string;
  country: string;
  groupId: string;
}

export enum EstablishmentOrigin {
  BRAZIL__CPF = 'BRAZIL__CPF',
  BRAZIL__CNPJ = 'BRAZIL__CNPJ'
}
