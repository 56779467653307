var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "card-loading" }, [
    _c("div", { staticClass: "card-loading__header" }, [
      _c("span", { staticClass: "group-label" }, [
        _vm._v(_vm._s(_vm.$t("myCompanies.group"))),
      ]),
      _c("span", { staticClass: "group-name" }),
      _vm._m(0),
    ]),
    _c(
      "table",
      { staticClass: "card-loading__table" },
      _vm._l(10, function (index) {
        return _c(
          "tr",
          { key: `loading-${index}`, staticClass: "table__row" },
          _vm._l(10, function (item) {
            return _c("td", { key: `${item}-${index}` })
          }),
          0
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flag-container" }, [
      _c("span", { staticClass: "flag-container__flag" }),
      _c("span", { staticClass: "flag-container__country" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }