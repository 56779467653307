
import FormatNationalRegisterMixin from '@/mixins/FormatNationalRegisterMixin';
import mixins from 'vue-typed-mixins';
import { EstablishmentOrigin } from '../../Types';

export default mixins(FormatNationalRegisterMixin).extend({
  name: 'BrazilTable',
  props: {
    rows: {
      type: Array as () => Array<Record<string, unknown>>,
      required: true,
    },
  },
  data() {
    return {
      rowsData: [],
      header: {
        alias: [
          `${this.$t('registerCompanyTable.headers.cnpj')}/CPF`,
          this.$t('name'),
          this.$t('location'),
          this.$t('registerCompanyTable.headers.rt'),
          this.$t('registerCompanyTable.headers.polling'),
          this.$t('registerCompanyTable.headers.retreat'),
        ],
        columnsToShow: [
          'nationalRegister',
          'personalIdentifier',
          'name',
          'state',
          'taxRegime',
          'retreat',
          'polling',
        ] as string[],
      },
      tributaryRegime: [
        { name: 'SIMPLES', value: 'SIMPLES' },
        { name: 'PRESUMIDO', value: 'PRESUMIDO' },
        { name: 'REAL', value: 'REAL' },
        { name: 'ARBITRADO', value: 'ARBITRADO' },
        { name: 'ISENTO', value: 'ISENTO' },
        { name: 'IMUNE', value: 'IMUNE' },
        { name: 'PESSOA FÍSICA', value: 'naturalPerson' },
      ] as Array<{ name: string; value: string }>,
      polling: [
        { name: 'COMPETÊNCIA', value: 'COMPETENCIA' },
        { name: 'ESTIMATIVA', value: 'ESTIMATIVA' },
        { name: 'CAIXA', value: 'CAIXA' },
        { name: 'TRIMESTRAL', value: 'TRIMESTRAL' },
        { name: 'ANUAL', value: 'ANUAL' },
        { name: 'OUTROS', value: 'OUTROS' },
        { name: 'PESSOA FÍSICA', value: 'naturalPerson' },
      ] as Array<{ name: string; value: string }>,
      retreat: [
        { name: 'TRIBUTO E DOCUMENTO', value: 'document' },
        { name: 'TRIBUTO E FORNECEDOR', value: 'provider' },
        { name: 'ACUMULADO POR TRIBUTO', value: 'tax' },
        { name: 'PESSOA FÍSICA', value: 'naturalPerson' },
      ] as Array<{ name: string; value: string }>,
    };
  },
  methods: {
    getTributaryRegime(value: any) {
      const data = this.tributaryRegime.find((d) => d.value === value);
      return data?.name || '';
    },
    getPolling(value: any) {
      const data = this.polling.find((d) => d.value === value);
      return data?.name || '';
    },
    getRetreat(value: any) {
      const data = this.retreat.find((d) => d.value === value);
      return data?.name || '';
    },
    getOrigin(nationalRegister: string): EstablishmentOrigin {
      return nationalRegister.length === 14
        ? EstablishmentOrigin.BRAZIL__CNPJ
        : EstablishmentOrigin.BRAZIL__CPF;
    },
  },
});
