
import mixins from 'vue-typed-mixins';
import CreateGroupHeadOfficeTableMixin from '@/resources/my-companies/components/CreateGroupHeadOfficeTables/CreateGroupHeadOfficeTableMixin';

export default mixins(CreateGroupHeadOfficeTableMixin).extend({
  name: 'CreateGroupHeadOfficeTable',
  props: {
    country: {
      type: String as () => string,
      required: true,
    },
    rows: {
      type: Array as () => Array<Record<string, unknown>>,
      required: true,
    },
  },
});
