var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("input-validation", {
    staticClass: "whitespace-nowrap relative",
    attrs: { rules: _vm.rules, slim: true, mode: _vm.mode },
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function ({ errors }) {
            return [
              _vm._t("input", null, { errors: errors }),
              _c(
                "small",
                { staticClass: "absolute -bottom-4 p-invalid left-0" },
                [_vm._v(" " + _vm._s(errors[0]) + " ")]
              ),
            ]
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }