var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "group" },
    [
      _vm._t("header"),
      _c("GroupCardTable", {
        attrs: {
          "header-labels": _vm.headerLabels,
          "expandable-labels": _vm.expandableLabels,
          "table-data": _vm.tableData,
          "expandable-items": "branchs",
          "expandable-total": _vm.expandableTotal,
          "expandable-loading": _vm.expandableLoading,
          "expandable-paginator-label": _vm.expandablePaginatorLabel,
        },
        on: {
          handleExpandableItems: _vm.handleExpandableItems,
          changePageSize: function ($event) {
            return _vm.$emit("changePageSize", $event)
          },
          goToLastPage: function ($event) {
            return _vm.$emit("goToLastPage")
          },
          goToFirstPage: function ($event) {
            return _vm.$emit("goToFirstPage")
          },
          nextPage: ($event) => _vm.$emit("nextPage", $event),
          previousPage: ($event) => _vm.$emit("previousPage", $event),
        },
        scopedSlots: _vm._u(
          [
            {
              key: "body",
              fn: function ({ item, column }) {
                return [_vm._t("body", null, { item: item, column: column })]
              },
            },
            {
              key: "expandable",
              fn: function ({ item }) {
                return [_vm._t("expandable", null, { item: item })]
              },
            },
          ],
          null,
          true
        ),
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }