var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container-fluid" }, [
    _c("div", { staticClass: "row p-ai-end p-md-fluid" }, [
      _c(
        "div",
        { staticClass: "col-12 col-sm-12 col-md-12" },
        [
          _c(
            "b-alert",
            { staticClass: "mb-2", attrs: { variant: "warning", show: "" } },
            [
              _c("h5", { staticClass: "alert-heading mt-2 mb-0" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        `companyGroupComponent.notice.processingWarning.title.${_vm.operationTitle}`
                      )
                    ) +
                    " "
                ),
              ]),
              _c("p", { staticClass: "mb-2" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "companyGroupComponent.notice.processingWarning.message"
                      )
                    ) +
                    " "
                ),
              ]),
            ]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }