
import GroupCard from '@/common/components/groupCard/GroupCard.vue';
import CorporateControlService from '@/common/services/corporate-control/CorporateControlService';
import EditDataInTableInput from '@/resources/my-companies/components/EditDataInTableInput.vue';
import EditDataInTableSelect from '@/resources/my-companies/components/EditDataInTableSelect.vue';
import FilterTable from '@/resources/my-companies/components/FilterTable.vue';
import CountryGroupCardMixin from '@/resources/my-companies/components/GroupCard/CountryGroupCardMixin';
import GroupCardHeader from '@/resources/my-companies/components/GroupCardHeader.vue';
import StatusCompany from '@/resources/my-companies/components/StatusCompany.vue';
import TaxMixin from '@/resources/my-companies/components/TaxMixin';
import { Company, Establishment } from '@roit-intern/roit-company-interfaces';
import mixins from 'vue-typed-mixins';

export default mixins(TaxMixin, CountryGroupCardMixin).extend({
  name: 'BrazilGroupCard',
  components: {
    GroupCard,
    StatusCompany,
    EditDataInTableInput,
    EditDataInTableSelect,
    FilterTable,
    GroupCardHeader,
  },
  data() {
    return {
      naturalPerson: 'naturalPerson',
      headerLabels: [
        this.$t('myCompanies.groupCardTable.brazil.nationalRegister'),
        this.$t('myCompanies.groupCardTable.brazil.name'),
        this.$t('myCompanies.groupCardTable.brazil.location'),
        this.$t('myCompanies.groupCardTable.brazil.municipalRegistration'),
        this.$t('myCompanies.groupCardTable.brazil.stateRegistration'),
        this.$t('myCompanies.groupCardTable.brazil.taxRegime'),
        this.$t('myCompanies.groupCardTable.brazil.assessment'),
        this.$t('myCompanies.groupCardTable.brazil.retreat'),
        this.$t('myCompanies.groupCardTable.brazil.statusRfb'),
      ],
      expandableLabels: [
        {
          label: this.$t(
            'myCompanies.groupCardTable.brazil.expandable.nationalRegister',
          ),
        },
        {
          label: this.$t('myCompanies.groupCardTable.brazil.expandable.name'),
          field: 'name',
        },
        {
          label: this.$t(
            'myCompanies.groupCardTable.brazil.expandable.location',
          ),
          field: 'address.state',
        },
        {
          label: this.$t(
            'myCompanies.groupCardTable.brazil.expandable.municipalRegistration',
          ),
          field: 'municipalRegistration',
        },
        {
          label: this.$t(
            'myCompanies.groupCardTable.brazil.expandable.stateRegistration',
          ),
          field: 'stateRegistration',
        },
        {
          label: this.$t('myCompanies.groupCardTable.brazil.statusRfb'),
          field: 'legalSituation',
        },
      ],
    };
  },
  methods: {
    async updateMunicipalRegistration(
      item: Establishment,
      municipalRegistration: string,
    ): Promise<void> {
      try {
        await CorporateControlService.updateEstablishment(
          {
            municipalRegistration,
            origin: (item as unknown as { origin: string }).origin,
          },
          item.identifier,
        );
      } catch {
        this.errorToast({
          text: this.$t(
            'myCompanies.groupCardTable.brazil.notifications.failToUpdateMunicipalRegistration',
          ) as string,
        });
      }
    },
    async updateStateRegistration(
      item: Establishment,
      stateRegistration: string,
    ): Promise<void> {
      try {
        await CorporateControlService.updateEstablishment(
          {
            stateRegistration,
            origin: (item as unknown as { origin: string }).origin,
          },
          item.identifier,
        );
      } catch {
        this.errorToast({
          text: this.$t(
            'myCompanies.groupCardTable.brazil.notifications.failToUpdateStateRegistration',
          ) as string,
        });
      }
    },
    async updateTaxRegimeType(item: Company, type: string): Promise<void> {
      try {
        await CorporateControlService.updateCompany(
          {
            taxRegime: type,
            origin: (item as unknown as { origin: string }).origin,
          },
          item.id as string,
        );
      } catch {
        this.errorToast({
          text: this.$t(
            'myCompanies.groupCardTable.brazil.notifications.failToUpdateTaxRegime',
          ) as string,
        });
      }
    },
    async updatePolling(item: Company, polling: string): Promise<void> {
      try {
        await CorporateControlService.updateCompany(
          {
            polling,
            origin: (item as unknown as { origin: string }).origin,
          },
          item.id as string,
        );
      } catch {
        this.errorToast({
          text: this.$t(
            'myCompanies.groupCardTable.brazil.notifications.failToUpdatePolling',
          ) as string,
        });
      }
    },
    async updateTaxGuide(item: Company, taxGuide: string): Promise<void> {
      try {
        await CorporateControlService.updateCompany(
          {
            retreat: taxGuide,
            origin: (item as unknown as { origin: string }).origin,
          },
          item.id as string,
        );
      } catch {
        this.errorToast({
          text: this.$t(
            'myCompanies.groupCardTable.brazil.notifications.failToUpdateTaxGuide',
          ) as string,
        });
      }
    },
  },
});
