import MyCompaniesService from '@/common/services/corporate-control/CorporateControlService';
import { FindGrouped } from '@/common/services/corporate-control/types';
import FormatNationalRegisterMixin from '@/mixins/FormatNationalRegisterMixin';
import NotificationMixin from '@/mixins/NotificationMixin';
import { Orientation } from '@/types';
import { Establishment } from '@roit-intern/roit-company-interfaces';
import mixins from 'vue-typed-mixins';

export default mixins(NotificationMixin, FormatNationalRegisterMixin).extend({
  name: 'CountryGroupCardMixin',
  props: {
    group: {
      type: Object as () => FindGrouped,
      required: true,
    },
    expandableLoading: {
      type: Array as () => boolean[],
      required: true,
    },
  },
  data() {
    return {
      perPage: '10',
      totalBranchs: 0,
    };
  },
  methods: {
    updateCompanyBranchs(companyId: string, branchs: Establishment[]): void {
      const companyIdx = this.group.companies.findIndex(
        (company) => company.id === companyId,
      );
      const company = this.group.companies[companyIdx];
      this.group.companies.splice(companyIdx, 1, {
        ...company,
        branchs,
      });
    },
    async handleExpandableItems(
      item: Establishment & { branchs: Establishment[] },
    ) {
      const notHasBranchs = !item.branchs[0]?.id;
      if (notHasBranchs) {
        try {
          const { branchs, metadata } = await MyCompaniesService.findBranchsByCompanyId(
            item.id as string,
            {
              limit: this.perPage,
            },
          );
          this.totalBranchs = metadata.total || 0;
          this.updateCompanyBranchs(item.id as string, branchs);
          this.$emit('updateExpandableLoad', { companyId: item.id, status: false });
        } catch {
          this.errorToast({
            text: this.$t(
              'myCompanies.notifications.failToGetBranchs',
            ) as string,
          });
        }
      }
    },
    changePageSize(value: string): void {
      this.perPage = value;
      this.goToFirstPage();
    },
    async goToLastPage(): Promise<void> {
      const company = this.group.companies[0];
      try {
        this.$emit('updateExpandableLoad', { companyId: company.id, status: true });
        const { branchs } = await MyCompaniesService.findBranchsByCompanyId(
          company.id,
          {
            limit: this.perPage,
            orientation: Orientation.LAST_PAGE,
          },
        );
        this.updateCompanyBranchs(company.id, branchs);
      } catch {
        this.errorToast({
          text: this.$t(
            'myCompanies.notifications.failToGetBranchs',
          ) as string,
        });
      }
      this.$emit('updateExpandableLoad', { companyId: company.id, status: false });
    },
    async goToFirstPage(): Promise<void> {
      const company = this.group.companies[0];
      try {
        this.$emit('updateExpandableLoad', { companyId: company.id, status: true });
        const { branchs } = await MyCompaniesService.findBranchsByCompanyId(
          company.id,
          {
            limit: this.perPage,
          },
        );
        this.updateCompanyBranchs(company.id, branchs);
      } catch {
        this.errorToast({
          text: this.$t(
            'myCompanies.notifications.failToGetBranchs',
          ) as string,
        });
      }
      this.$emit('updateExpandableLoad', { companyId: company.id, status: false });
    },
    async nextPage(currentBranchs: Establishment[]): Promise<void> {
      const lastBranch = currentBranchs[currentBranchs.length - 1];
      if (lastBranch) {
        try {
          this.$emit('updateExpandableLoad', { companyId: lastBranch.companyId, status: true });
          const { branchs } = await MyCompaniesService.findBranchsByCompanyId(
            lastBranch.companyId,
            {
              limit: this.perPage,
              cursor: lastBranch.nationalRegister,
            },
          );
          this.updateCompanyBranchs(lastBranch.companyId, branchs);
        } catch {
          this.errorToast({
            text: this.$t(
              'myCompanies.notifications.failToGetBranchs',
            ) as string,
          });
        }
        this.$emit('updateExpandableLoad', { companyId: lastBranch.companyId, status: false });
      }
    },
    async previousPage(currentBranchs: Establishment[]): Promise<void> {
      const firstBranch = currentBranchs[0];
      if (firstBranch) {
        this.$emit('updateExpandableLoad', { companyId: firstBranch.companyId, status: true });
        try {
          const { branchs } = await MyCompaniesService.findBranchsByCompanyId(
            firstBranch.companyId,
            {
              limit: this.perPage,
              cursor: firstBranch.nationalRegister,
              orientation: Orientation.PREVIOUS_PAGE,
            },
          );
          this.updateCompanyBranchs(firstBranch.companyId, branchs);
        } catch {
          this.errorToast({
            text: this.$t(
              'myCompanies.notifications.failToGetBranchs',
            ) as string,
          });
        }
        this.$emit('updateExpandableLoad', { companyId: firstBranch.companyId, status: false });
      }
    },
  },
});
