
import Vue from 'vue';

export default Vue.extend({
  name: 'Paginator',
  props: {
    totalItems: {
      type: Number as () => number,
      required: true,
    },
    itemLabel: {
      type: String as () => string,
      required: true,
    },
  },
  data() {
    return {
      actualPage: 1,
      selectedPageSize: '10',
      isLoading: false,
      pageSizeOptions: [
        { value: '10' },
        { value: '25' },
        { value: '50' },
        { value: '100' },
        { value: '200' },
      ],
    };
  },
  computed: {
    lastPage(): number {
      return Math.ceil(this.totalItems / +this.selectedPageSize);
    },
    itemsDisplayed(): number {
      const totalItemsToDisplay = +this.selectedPageSize * this.actualPage;
      return totalItemsToDisplay >= this.totalItems
        ? this.totalItems
        : totalItemsToDisplay;
    },
  },
  methods: {
    changePageSize(): void {
      this.actualPage = 1;
      this.$emit('changePageSize', this.selectedPageSize);
    },
    goToLastPage(): void {
      this.actualPage = this.lastPage;
      this.$emit('goToLastPage');
    },
    goToFirstPage(): void {
      this.actualPage = 1;
      this.$emit('goToFirstPage');
    },
    nextPage(): void {
      this.actualPage += 1;
      this.$emit('nextPage');
    },
    previousPage(): void {
      this.actualPage -= 1;
      this.$emit('previousPage');
    },
  },
});
