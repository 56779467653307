
import { FindGrouped } from '@/common/services/corporate-control/types';
import GroupCardMixin from '@/resources/my-companies/components/GroupCard/GroupCardMixin';
import mixins from 'vue-typed-mixins';

export default mixins(GroupCardMixin).extend({
  name: 'GroupCard',
  props: {
    group: {
      type: Object as () => FindGrouped,
      required: true,
    },
  },
  data() {
    return {
      expandableLoading: [] as boolean[],
    };
  },
  mounted() {
    this.group.companies.forEach(() => this.expandableLoading.push(true));
  },
  methods: {
    updateExpandableLoad({ companyId, status }: {companyId: string; status: boolean}): void {
      const loadingIdx = this.group.companies.findIndex((company) => company.id === companyId);
      this.expandableLoading.splice(loadingIdx, 1, status);
    },
  },
});
