var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("GroupCard", {
    attrs: {
      "header-labels": _vm.headerLabels,
      "expandable-labels": _vm.expandableLabels,
      "expandable-total": _vm.totalBranchs,
      "expandable-loading": _vm.expandableLoading,
      "table-data": _vm.group.companies,
      "expandable-paginator-label": _vm.$t(
        "myCompanies.groupCardTable.expandablePaginatorLabel"
      ),
    },
    on: {
      handleExpandableItems: _vm.handleExpandableItems,
      changePageSize: _vm.changePageSize,
      goToLastPage: _vm.goToLastPage,
      goToFirstPage: _vm.goToFirstPage,
      nextPage: _vm.nextPage,
      previousPage: _vm.previousPage,
    },
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [
            _c("GroupCardHeader", {
              attrs: { group: _vm.group },
              on: {
                registerGroup: function ($event) {
                  return _vm.$emit("registerGroup")
                },
              },
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function ({ item, column }) {
          return [
            column ===
            _vm.$t("myCompanies.groupCardTable.brazil.nationalRegister")
              ? _c("td", { staticClass: "data" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.formatNationalRegister(
                          item.nationalRegister,
                          item.origin
                        )
                      ) +
                      " "
                  ),
                ])
              : _vm._e(),
            column === _vm.$t("myCompanies.groupCardTable.brazil.name")
              ? _c(
                  "td",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip.top",
                        value: {
                          value: item.name,
                          disabled: item.name.length < 20,
                        },
                        expression:
                          "{\n        value: item.name,\n        disabled: item.name.length < 20,\n      }",
                        modifiers: { top: true },
                      },
                    ],
                    staticClass: "data",
                  },
                  [_vm._v(" " + _vm._s(item.name) + " ")]
                )
              : _vm._e(),
            column === _vm.$t("myCompanies.groupCardTable.brazil.location")
              ? _c("td", { staticClass: "data" }, [
                  _vm._v(
                    " " +
                      _vm._s(item.address && item.address.state) +
                      " - " +
                      _vm._s(item.address && item.address.city) +
                      " "
                  ),
                ])
              : _vm._e(),
            column ===
            _vm.$t("myCompanies.groupCardTable.brazil.municipalRegistration")
              ? _c(
                  "td",
                  { staticClass: "data edit" },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          item.municipalRegistration === "notInformed" ||
                            !item.municipalRegistration
                            ? _vm.$t("notInformed")
                            : item.municipalRegistration
                        ) +
                        " "
                    ),
                    _c("EditDataInTableInput", {
                      staticClass: "edit__icon",
                      attrs: {
                        label: `${_vm.$t("edit")} ${_vm.$t(
                          "myCompanies.groupCardTable.brazil.municipalRegistrationFull"
                        )}`,
                        rules: "required",
                        "input-type": "numeric",
                        input: item.municipalRegistration,
                      },
                      on: {
                        "update:input": function ($event) {
                          return _vm.$set(item, "municipalRegistration", $event)
                        },
                        saveChanges: ($event) =>
                          _vm.updateMunicipalRegistration(item, $event),
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            column ===
            _vm.$t("myCompanies.groupCardTable.brazil.stateRegistration")
              ? _c(
                  "td",
                  { staticClass: "data edit" },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          item.stateRegistration === "notInformed" ||
                            !item.stateRegistration
                            ? _vm.$t("notInformed")
                            : item.stateRegistration
                        ) +
                        " "
                    ),
                    _c("EditDataInTableInput", {
                      staticClass: "edit__icon",
                      attrs: {
                        label: `${_vm.$t("edit")} ${_vm.$t(
                          "myCompanies.groupCardTable.brazil.stateRegistrationFull"
                        )}`,
                        rules: "required",
                        "input-type": "numeric",
                        input: item.stateRegistration,
                      },
                      on: {
                        "update:input": function ($event) {
                          return _vm.$set(item, "stateRegistration", $event)
                        },
                        saveChanges: ($event) =>
                          _vm.updateStateRegistration(item, $event),
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            column === _vm.$t("myCompanies.groupCardTable.brazil.taxRegime")
              ? _c(
                  "td",
                  { staticClass: "data edit" },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.getTributaryRegime(item.taxRegime.type) ||
                            _vm.$t("notInformed")
                        ) +
                        " "
                    ),
                    item.taxRegime.type !== _vm.naturalPerson
                      ? _c("EditDataInTableSelect", {
                          staticClass: "edit__icon",
                          attrs: {
                            select: item.taxRegime.type,
                            label: `${_vm.$t("edit")} ${_vm.$t(
                              "myCompanies.groupCardTable.brazil.taxRegime"
                            )}`,
                            options: _vm.tributaryRegime.filter(
                              (data) => data.value !== _vm.naturalPerson
                            ),
                          },
                          on: {
                            "update:select": function ($event) {
                              return _vm.$set(item.taxRegime, "type", $event)
                            },
                            saveChanges: ($event) =>
                              _vm.updateTaxRegimeType(item, $event),
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            column === _vm.$t("myCompanies.groupCardTable.brazil.assessment")
              ? _c(
                  "td",
                  { staticClass: "data edit" },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.getPolling(item.taxRegime.assessment) ||
                            _vm.$t("notInformed")
                        ) +
                        " "
                    ),
                    item.taxRegime.type !== _vm.naturalPerson
                      ? _c("EditDataInTableSelect", {
                          staticClass: "edit__icon",
                          attrs: {
                            select: item.taxRegime.assessment,
                            label: `${_vm.$t("edit")} ${_vm.$t(
                              "myCompanies.groupCardTable.brazil.assessment"
                            )}`,
                            options: _vm.polling.filter(
                              (data) => data.value !== _vm.naturalPerson
                            ),
                          },
                          on: {
                            "update:select": function ($event) {
                              return _vm.$set(
                                item.taxRegime,
                                "assessment",
                                $event
                              )
                            },
                            saveChanges: ($event) =>
                              _vm.updatePolling(item, $event),
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            column === _vm.$t("myCompanies.groupCardTable.brazil.retreat")
              ? _c(
                  "td",
                  { staticClass: "data edit" },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.getRetreat(item.taxGuide) || _vm.$t("notInformed")
                        ) +
                        " "
                    ),
                    item.taxRegime.type !== _vm.naturalPerson
                      ? _c("EditDataInTableSelect", {
                          staticClass: "edit__icon",
                          attrs: {
                            select: item.taxGuide,
                            label: `${_vm.$t("edit")} ${_vm.$t(
                              "myCompanies.groupCardTable.brazil.retreat"
                            )}`,
                            options: _vm.retreat.filter(
                              (data) => data.value !== _vm.naturalPerson
                            ),
                          },
                          on: {
                            "update:select": function ($event) {
                              return _vm.$set(item, "taxGuide", $event)
                            },
                            saveChanges: ($event) =>
                              _vm.updateTaxGuide(item, $event),
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            column === _vm.$t("myCompanies.groupCardTable.brazil.statusRfb")
              ? _c(
                  "td",
                  { staticClass: "data" },
                  [
                    _c("StatusCompany", {
                      attrs: { status: item.legalSituation },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]
        },
      },
      {
        key: "expandable",
        fn: function ({ item }) {
          return [
            _c("td", [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.formatNationalRegister(
                      item.nationalRegister,
                      item.origin
                    )
                  ) +
                  " "
              ),
            ]),
            _c("td", [_vm._v(" " + _vm._s(item.name) + " ")]),
            _c("td", [
              _vm._v(
                " " +
                  _vm._s(item.address && item.address.state) +
                  " - " +
                  _vm._s(item.address && item.address.city) +
                  " "
              ),
            ]),
            _c(
              "td",
              { staticClass: "edit" },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      item.municipalRegistration || _vm.$t("notInformed")
                    ) +
                    " "
                ),
                _c("EditDataInTableInput", {
                  staticClass: "edit__icon",
                  attrs: {
                    label: `${_vm.$t("edit")} ${_vm.$t(
                      "myCompanies.groupCardTable.brazil.municipalRegistration"
                    )}`,
                    rules: "required",
                    "input-type": "numeric",
                    input: item.municipalRegistration,
                  },
                  on: {
                    "update:input": function ($event) {
                      return _vm.$set(item, "municipalRegistration", $event)
                    },
                    saveChanges: ($event) =>
                      _vm.updateStateRegistration(item, $event),
                  },
                }),
              ],
              1
            ),
            _c(
              "td",
              { staticClass: "edit" },
              [
                _vm._v(
                  " " +
                    _vm._s(item.stateRegistration || _vm.$t("notInformed")) +
                    " "
                ),
                _c("EditDataInTableInput", {
                  staticClass: "edit__icon",
                  attrs: {
                    label: `${_vm.$t("edit")} ${_vm.$t(
                      "myCompanies.groupCardTable.brazil.stateRegistration"
                    )}`,
                    rules: "required",
                    "input-type": "numeric",
                    input: item.stateRegistration,
                  },
                  on: {
                    "update:input": function ($event) {
                      return _vm.$set(item, "stateRegistration", $event)
                    },
                    saveChanges: ($event) =>
                      _vm.updateStateRegistration(item, $event),
                  },
                }),
              ],
              1
            ),
            _c(
              "td",
              [_c("StatusCompany", { attrs: { status: item.legalSituation } })],
              1
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }