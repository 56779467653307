
import CreateGroupModalContent from '@/resources/my-companies/components/CreateGroupModalContent.vue';
import ConfirmCreateForeignGroup from '@/resources/my-companies/components/ConfirmCreateForeignGroup.vue';
import mixins from 'vue-typed-mixins';
import NotificationMixin from '@/mixins/NotificationMixin';
import CorporatesService from '@/common/services/company-reborn/CorporatesService';
import { mapMutations } from 'vuex';
import request from 'axios';
import { CompanyInfo, GroupInfo } from '../Types';

export type CreateGroupModalContentRef = InstanceType<typeof CreateGroupModalContent>

export default mixins(NotificationMixin).extend({

  name: 'CreateGroupModal',
  components: {
    CreateGroupModalContent,
    ConfirmCreateForeignGroup,
  },
  props: {
    control: {
      type: Boolean as () => boolean,
      default: false,
    },
    group: {
      type: Object as () => GroupInfo,
      default: () => ({} as GroupInfo),
    },
  },
  computed: {
    displayModal: {
      get(): boolean {
        return this.control;
      },
      set(value: boolean) {
        this.$emit('update:control', value);
      },
    },
  },
  data() {
    return {
      showConfirmModal: false,
      groupData: {} as {name: string; country: string},
      companiesInfo: [] as CompanyInfo[],
    };
  },
  methods: {
    ...mapMutations({
      handleLoading: 'handleLoading',
    }),
    async handleRegisterGroups() {
      const createGroup = this.$refs.createGroupModalContent as CreateGroupModalContentRef;
      const isGroupNameValid = await createGroup.validGroupName();
      if (!isGroupNameValid) {
        return;
      }
      this.groupData = createGroup.groupData;
      if (this.group?.groupId) {
        await this.addCompaniesInGroup();
      } else {
        if (createGroup.groupData.country !== 'Brazil') {
          this.showConfirmModal = true;
          return;
        }
        await this.registerGroups();
      }
    },
    async registerGroups() {
      const { country, name } = this.groupData;
      const { masterId } = this.$store.state.auth.userCompany;

      this.handleLoading(true);

      try {
        const data = {
          country,
          name,
          masterId,
          companiesInfo: this.companiesInfo,
        };
        await CorporatesService.create(data);
        this.$emit('registerGroup');
      } catch {
        this.errorToast({
          text: this.$t('myCompanies.notifications.failToRegisterGroup') as string,
        });
      }
      this.displayModal = false;
      this.handleLoading(false);
    },
    async addCompaniesInGroup() {
      try {
        this.handleLoading(true);
        const { masterId } = this.$store.state.auth.userCompany;

        const companies = this.companiesInfo.map(
          (company) => ({
            ...company,
            companyGroupId: this.group.groupId,
            country: this.group.country,
            masterId,
          }),
        );
        await CorporatesService.createCompanyInBatch({
          companies,
        });
        this.$emit('registerGroup');
      } catch (e) {
        if (request.isAxiosError(e) && e.response) {
          if (e.response.data.message.toLowerCase() === 'invalid establishment type') {
            this.errorToast({
              text: this.$t('myCompanies.notifications.failToAddCompanyInvalidType') as string,
            });
          } else {
            this.errorToast({
              text: this.$t('myCompanies.notifications.failToAddCompany') as string,
            });
          }
        }
      }
      this.displayModal = false;
      this.handleLoading(false);
    },
  },

});
