import InputValidation from '@/common/components/input-validation/InputValidationV2.vue';
import { VForm } from '@/types';
import { ProviderInstance } from 'vee-validate/dist/types/types.d';
import Vue from 'vue';

export type FormRef = InstanceType<typeof Vue> & {
  validForm: () => Promise<boolean>;
  clearValidations: () => void;
  clearForm: () => void;
};

export default Vue.extend({
  name: 'FormMixin',
  components: {
    InputValidation,
  },
  data() {
    return {
      localForm: {
        establishmentType: '',
      },
    };
  },
  methods: {
    async validForm(): Promise<boolean> {
      const form = this.$refs.form as VForm;
      return form.validate();
    },
    clearValidations() {
      (this.$refs.form as ProviderInstance).reset();
    },
    clearForm() {
      this.localForm = {
        establishmentType: this.localForm.establishmentType,
      };
    },
  },
});
