var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("form", { staticClass: "form" }, [
    _c(
      "fieldset",
      { staticClass: "form__fieldset" },
      [
        _c("legend", { staticClass: "form__title" }, [
          _vm._v(
            " " +
              _vm._s(_vm.$t("myCompanies.modals.createGroup.headOfficeData")) +
              " "
          ),
        ]),
        _c(_vm.getForm(_vm.country), {
          ref: "form",
          tag: "component",
          attrs: { country: _vm.country, form: _vm.localForm },
          on: {
            "update:form": function ($event) {
              _vm.localForm = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "button",
              fn: function () {
                return [
                  _c("Button", {
                    staticClass: "outlined",
                    style: { width: "235px" },
                    attrs: {
                      icon: "icon-r-circle-plus",
                      label: _vm.$t(
                        "myCompanies.modals.createGroup.addEstablishment"
                      ),
                    },
                    on: { click: _vm.addHeadOffice },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }