
import { debounce } from 'lodash';
import Vue from 'vue';

export default Vue.extend({
  name: 'HeaderSection',
  methods: {
    // eslint-disable-next-line func-names
    onSearch: debounce(function (search: string) {
      const searchText = search.toString().trim();
      // @ts-ignore
      this.$emit('onSearch', searchText);
    }, 500),
  },
});
