
import EditGroupName from '@/common/components/companyGroup/modal/CompanyGroupModal.vue';
import { InUpdateFrom } from '@/common/components/companyGroup/types';
import CountriesService from '@/common/services/CountriesService';
import CorporateControlService from '@/common/services/corporate-control/CorporateControlService';
import NotificationMixin from '@/mixins/NotificationMixin';
import CreateGroupModal from '@/resources/my-companies/components/CreateGroupModal.vue';
import mixins from 'vue-typed-mixins';
import { GroupInfo } from '../Types';

export default mixins(NotificationMixin).extend({
  name: 'GroupCardHeader',
  components: {
    EditGroupName,
    CreateGroupModal,
  },
  props: {
    group: {
      type: Object as () => GroupInfo,
      required: true,
    },
  },
  data() {
    return {
      flagSrc: '' as string,
      translations: {} as Record<string, string>,
      showEditNameModal: false,
      showCompanyGroupModal: false,
      modalData: {
        isUpdateFrom: InUpdateFrom.CompanyGroup,
        data: {
          groupName: this.group.name,
        },
      },
    };
  },
  computed: {
    nameInCurrentLanguague(): string {
      return this.translations.pt;
    },
  },
  async mounted() {
    const [{ flag, translations }] = await CountriesService.getByFullName(this.group.country, ['flag', 'translations']);
    this.flagSrc = flag;
    this.translations = translations;
  },
  methods: {
    async editGroupName({ groupName }: {groupName: string}) {
      try {
        await CorporateControlService.updateCompanyGroupName(groupName, this.group.groupId);
        this.successToast({
          text: this.$t('MyProductsPage.email.modal.requestSucess') as string,
        });
        this.group.name = groupName;
      } catch {
        this.errorToast({
          text: this.$t('onboarding.registerCompany.table.errorInRegistration') as string,
        });
      }
    },
  },
});
