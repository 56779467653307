import { EstablishmentOrigin } from '@/resources/my-companies/Types';
import Vue from 'vue';

export default Vue.extend({
  name: 'FormatNationalRegisterMixin',
  methods: {
    formatNationalRegister(
      nationalRegister: string,
      origin: EstablishmentOrigin,
    ): string {
      const formates = {
        [EstablishmentOrigin.BRAZIL__CNPJ]: {
          regex: /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
          format: '$1.$2.$3/$4-$5',
        },
        [EstablishmentOrigin.BRAZIL__CPF]: {
          regex: /^(\d{3})(\d{3})(\d{3})(\d{2})/,
          format: '$1.$2.$3-$4',
        },
      };
      const formatter = formates[origin];
      return formatter
        ? nationalRegister.replace(formatter.regex, formatter.format)
        : nationalRegister;
    },
  },
});
