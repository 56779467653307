import { render, staticRenderFns } from "./CreateGroupModalContent.vue?vue&type=template&id=09f634b8&scoped=true&"
import script from "./CreateGroupModalContent.vue?vue&type=script&lang=ts&"
export * from "./CreateGroupModalContent.vue?vue&type=script&lang=ts&"
import style0 from "./CreateGroupModalContent.vue?vue&type=style&index=0&id=09f634b8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09f634b8",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/jenkins/agent/workspace/roit-company-front/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('09f634b8')) {
      api.createRecord('09f634b8', component.options)
    } else {
      api.reload('09f634b8', component.options)
    }
    module.hot.accept("./CreateGroupModalContent.vue?vue&type=template&id=09f634b8&scoped=true&", function () {
      api.rerender('09f634b8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/resources/my-companies/components/CreateGroupModalContent.vue"
export default component.exports