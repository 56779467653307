var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("custom-table", {
    staticClass: "brazil-table",
    attrs: { "columns-data": _vm.header, data: _vm.rows, sortable: true },
    scopedSlots: _vm._u(
      [
        {
          key: "columns",
          fn: function ({ item, column }) {
            return [
              column === "nationalRegister"
                ? _c("td", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.formatNationalRegister(
                            item.nationalRegister,
                            _vm.getOrigin(item.nationalRegister)
                          )
                        ) +
                        " "
                    ),
                  ])
                : _vm._e(),
              column === "name"
                ? _c(
                    "td",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip.top",
                          value: {
                            value: item.name,
                            disabled: !item.name || item.name.length < 15,
                          },
                          expression:
                            "{\n        value: item.name,\n        disabled: !item.name || item.name.length < 15\n      }",
                          modifiers: { top: true },
                        },
                      ],
                      staticClass: "brazil-table__td",
                    },
                    [_vm._v(" " + _vm._s(item.name || "") + " ")]
                  )
                : _vm._e(),
              column === "state"
                ? _c(
                    "td",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip.top",
                          value: {
                            value: `${item.state}-${item.city}`,
                            disabled:
                              `${item.state || ""}${item.city || ""}`.length <
                              15,
                          },
                          expression:
                            "{\n        value: `${item.state}-${item.city}`,\n        disabled: `${item.state || ''}${item.city || ''}`.length < 15\n      }",
                          modifiers: { top: true },
                        },
                      ],
                      staticClass: "brazil-table__td",
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            item.state ? `${item.state}-${item.city}` : ""
                          ) +
                          " "
                      ),
                    ]
                  )
                : _vm._e(),
              column === "taxRegime"
                ? _c(
                    "td",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip.top",
                          value: {
                            value: _vm.getTributaryRegime(item.taxRegime),
                            disabled:
                              !_vm.getTributaryRegime(item.taxRegime) ||
                              _vm.getTributaryRegime(item.taxRegime).length <
                                15,
                          },
                          expression:
                            "{\n        value: getTributaryRegime(item.taxRegime),\n        disabled: (\n          !getTributaryRegime(item.taxRegime)\n          || getTributaryRegime(item.taxRegime).length < 15\n        )\n      }",
                          modifiers: { top: true },
                        },
                      ],
                      staticClass: "brazil-table__td",
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.getTributaryRegime(item.taxRegime)) +
                          " "
                      ),
                    ]
                  )
                : _vm._e(),
              column === "retreat"
                ? _c(
                    "td",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip.top",
                          value: {
                            value: _vm.getRetreat(item.retreat),
                            disabled:
                              !_vm.getRetreat(item.retreat) ||
                              _vm.getRetreat(item.retreat).length < 15,
                          },
                          expression:
                            "{\n        value: getRetreat(item.retreat),\n        disabled: (\n          !getRetreat(item.retreat)\n          || getRetreat(item.retreat).length < 15\n        )\n      }",
                          modifiers: { top: true },
                        },
                      ],
                      staticClass: "brazil-table__td",
                    },
                    [_vm._v(" " + _vm._s(_vm.getRetreat(item.retreat)) + " ")]
                  )
                : _vm._e(),
              column === "polling"
                ? _c(
                    "td",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip.top",
                          value: {
                            value: _vm.getPolling(item.polling),
                            disabled:
                              !_vm.getPolling(item.polling) ||
                              _vm.getPolling(item.polling).length < 15,
                          },
                          expression:
                            "{\n        value: getPolling(item.polling),\n        disabled: (\n          !getPolling(item.polling)\n          || getPolling(item.polling).length < 15\n        )\n      }",
                          modifiers: { top: true },
                        },
                      ],
                      staticClass: "brazil-table__td",
                    },
                    [_vm._v(" " + _vm._s(_vm.getPolling(item.polling)) + " ")]
                  )
                : _vm._e(),
            ]
          },
        },
        {
          key: "actions",
          fn: function (item) {
            return [_vm._t("actions", null, { item: item })]
          },
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }