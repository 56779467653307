
import Vue from 'vue';
import GroupCardTable from '@/common/components/groupCard/GroupCardTable.vue';

export default Vue.extend({
  name: 'GroupCard',
  components: {
    GroupCardTable,
  },
  props: {
    headerLabels: {
      type: Array as () => string[],
      required: true,
    },
    expandableLabels: {
      type: Array as () => {field: string; label: string}[],
      default: () => ([]),
    },
    expandablePaginatorLabel: {
      type: String,
      required: true,
    },
    tableData: {
      type: Array as () => unknown[],
      required: true,
    },
    expandableTotal: {
      type: Number as () => number,
      required: true,
    },
    expandableLoading: {
      type: Array as () => boolean[],
      required: true,
    },
  },
  methods: {
    handleExpandableItems(item: unknown) {
      this.$emit('handleExpandableItems', item);
    },
  },
});
