
import FormMixin from '@/resources/my-companies/components/CreateGroupHeadOfficeDataForms/FormMixin';
import CountriesService from '@/common/services/CountriesService';
import mixins from 'vue-typed-mixins';
import { CountryOptions } from '../Types';

export default mixins(FormMixin).extend({
  name: 'CreateGroupGroupDataForm',
  props: {
    form: {
      type: Object as () => {name: string; country: string},
      required: true,
    },
    updating: {
      type: Boolean as () => boolean,
      default: false,
    },
  },
  data() {
    return {
      countries: [] as CountryOptions[],
    };
  },
  watch: {
    form: {
      deep: true,
      async handler(): Promise<void> {
        if (this.updating) {
          this.countries = await CountriesService.getByFullName(this.form.country, ['flag', 'translations', 'name']);
        } else {
          this.countries = await CountriesService.getAll(['translations', 'flag', 'name']);
        }
      },
    },
  },
  methods: {
    normalize(str: string) {
      const withAccents = 'áàãâåäéèêëíìîïóòõôöúùûüçÁÀÃÂÅÄÉÈÊËÍÌÎÏÓÒÕÖÔÚÙÛÜÇ';
      const withoutAccents = 'aaaaaaeeeeiiiiooooouuuucAAAAAAEEEEIIIIOOOOOUUUUC';
      let newString = '';

      for (let i = 0; i < str.length; i += 1) {
        const char = str.charAt(i);
        const accentIndex = withAccents.indexOf(char);
        if (accentIndex > -1) {
          newString += withoutAccents.charAt(accentIndex);
        } else {
          newString += char;
        }
      }

      return newString.replaceAll(' ', '');
    },
    getNameInCurrentLanguague(country: CountryOptions): string {
      return country.translations.pt;
    },
  },
});
