<template>
  <div class="container-fluid">
    <div class="row p-ai-end p-md-fluid">
      <div class="col-12 col-sm-12 col-md-12">
        <b-alert
          class="mb-2"
          variant="warning"
          show
        >
          <h5 class="alert-heading mt-2 mb-0">
            {{ $t(`companyGroupComponent.notice.processingWarning.title.${operationTitle}`) }}
          </h5>
          <p class="mb-2">
            {{ $t('companyGroupComponent.notice.processingWarning.message') }}
          </p>
        </b-alert>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProcessingWarning',
  props: {
    operationTitle: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.container-fluid {
  padding: 0;
}
</style>
