var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "header" }, [
    _c("div", { staticClass: "header__container" }, [
      _c("span", { staticClass: "group-label" }, [
        _vm._v(_vm._s(_vm.$t("myCompanies.group"))),
      ]),
      _c("span", { staticClass: "group-name" }, [
        _vm._v(_vm._s(_vm.group.name)),
      ]),
      _c("div", { staticClass: "flag-container" }, [
        _c("img", {
          staticClass: "flag-container__flag",
          attrs: { src: _vm.flagSrc },
        }),
        _c("span", { staticClass: "flag-container__country" }, [
          _vm._v(_vm._s(_vm.nameInCurrentLanguague)),
        ]),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "header__container actions" },
      [
        _c(
          "div",
          {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip.top",
                value: _vm.$t("companyGroupComponent.tooltip.addCompanies"),
                expression: "$t('companyGroupComponent.tooltip.addCompanies')",
                modifiers: { top: true },
              },
            ],
            staticClass: "action",
            on: {
              click: function ($event) {
                _vm.showCompanyGroupModal = true
              },
            },
          },
          [_c("i", { staticClass: "icon-r-circle-plus color__secondary" })]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip.top",
                value: _vm.$t("companyGroupComponent.tooltip.editGroup"),
                expression: "$t('companyGroupComponent.tooltip.editGroup')",
                modifiers: { top: true },
              },
            ],
            staticClass: "action",
            on: {
              click: function ($event) {
                _vm.showEditNameModal = true
              },
            },
          },
          [_c("i", { staticClass: "icon-r-edit color__secondary" })]
        ),
        _c("CreateGroupModal", {
          attrs: { control: _vm.showCompanyGroupModal, group: _vm.group },
          on: {
            "update:control": function ($event) {
              _vm.showCompanyGroupModal = $event
            },
            registerGroup: function ($event) {
              return _vm.$emit("registerGroup")
            },
          },
        }),
        _c("EditGroupName", {
          attrs: { control: _vm.showEditNameModal, "edit-data": _vm.modalData },
          on: {
            "update:control": function ($event) {
              _vm.showEditNameModal = $event
            },
            companyGroupUpdate: ($event) => _vm.editGroupName($event),
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }