
import Vue from 'vue';
import CountriesService from '@/common/services/CountriesService';

export default Vue.extend({
  name: 'ConfirmCreateForeignGroup',
  props: {
    control: {
      type: Boolean as () => boolean,
      default: false,
    },
    groupData: {
      type: Object as () => { name: string; country: string },
      required: true,
    },
  },
  data() {
    return {
      country: '',
      flag: '',
    };
  },
  computed: {
    displayModal: {
      get(): boolean {
        return this.control;
      },
      set(value: boolean) {
        this.$emit('update:control', value);
      },
    },
  },
  watch: {
    groupData: {
      deep: true,
      async handler(value) {
        const result = await CountriesService.getByFullName(value.country);
        this.country = result[0].translations.pt;
        this.flag = result[0].flag;
      },
    },
  },
  methods: {
    confirm() {
      this.$emit('update:control', false);
      this.$emit('confirmRegister');
    },
  },
});
