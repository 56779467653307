var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("PageCard", {
    staticClass: "my-companies-page",
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [
            _c("div", { staticClass: "flex items-center px-2 py-3 gap-2" }, [
              _c(
                "div",
                {
                  staticClass: "rounded-full flex items-center p-1 back-icon",
                  on: { click: _vm.backToCompanies },
                },
                [
                  _c("i", {
                    staticClass: "pointer icon-r-arrow-left-single",
                    on: {
                      click: function ($event) {
                        return _vm.$router.push("/my-products")
                      },
                    },
                  }),
                ]
              ),
              _c("h5", { staticClass: "mb-0" }, [
                _vm._v(" " + _vm._s(_vm.$route.params.companyName) + " "),
              ]),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "content",
        fn: function () {
          return [
            _c("custom-table", {
              ref: "companyTable",
              attrs: {
                "columns-data": _vm.header,
                data: _vm.rowsData,
                loading: _vm.loading,
                paginate: true,
                "page-size-prop": _vm.perPage,
                "row-length": _vm.rowsDataTotalLength,
              },
              on: {
                currPage: _vm.updateCurrentPage,
                pageSize: _vm.updatePageSize,
              },
              scopedSlots: _vm._u([
                {
                  key: "columns",
                  fn: function ({ item, column }) {
                    return [
                      column === "tree"
                        ? _c("td", { staticClass: "hidden" })
                        : _vm._e(),
                      column === "nationalRegister"
                        ? _c(
                            "td",
                            { staticClass: "w-150" },
                            [
                              _c("InputMask", {
                                class: [
                                  {
                                    "text-danger": !_vm.getStatusActive(
                                      item.legalSituation
                                    ),
                                  },
                                  "p-inputtext-sm",
                                ],
                                attrs: {
                                  mask: "99.999.999/9999-99",
                                  readonly: true,
                                },
                                model: {
                                  value: item.nationalRegister,
                                  callback: function ($$v) {
                                    _vm.$set(item, "nationalRegister", $$v)
                                  },
                                  expression: "item.nationalRegister",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      column === "companyName"
                        ? _c(
                            "td",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip.top",
                                  value: {
                                    value: item.companyName,
                                    disabled:
                                      !item.companyName ||
                                      item.companyName.length < 40,
                                  },
                                  expression:
                                    "{\n            value: item.companyName,\n            disabled: !item.companyName || item.companyName.length < 40\n          }",
                                  modifiers: { top: true },
                                },
                              ],
                            },
                            [_vm._v(" " + _vm._s(item.companyName) + " ")]
                          )
                        : _vm._e(),
                      column === "address.city"
                        ? _c("td", { staticClass: "w-150" }, [
                            _vm._v(
                              " " +
                                _vm._s(item.address && item.address.city) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                      column === "address.state"
                        ? _c("td", [
                            _vm._v(
                              " " +
                                _vm._s(item.address && item.address.state) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                      column === "legalSituation"
                        ? _c(
                            "td",
                            { staticClass: "w-6" },
                            [
                              _c("StatusCompanyTable", {
                                attrs: { status: item.legalSituation || "" },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      column === "stateRegistration"
                        ? _c(
                            "td",
                            { staticClass: "w-130" },
                            [
                              _c("InputText", {
                                directives: [
                                  {
                                    name: "mask",
                                    rawName: "v-mask",
                                    value: ["###.###.###.###"],
                                    expression: "['###.###.###.###']",
                                  },
                                ],
                                staticClass: "p-inputtext-sm",
                                model: {
                                  value: item.stateRegistration,
                                  callback: function ($$v) {
                                    _vm.$set(item, "stateRegistration", $$v)
                                  },
                                  expression: "item.stateRegistration",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      column === "municipalRegistration"
                        ? _c(
                            "td",
                            { staticClass: "w-185" },
                            [
                              _c("InputText", {
                                directives: [
                                  {
                                    name: "mask",
                                    rawName: "v-mask",
                                    value: ["####################"],
                                    expression: "['####################']",
                                  },
                                ],
                                staticClass: "p-inputtext-sm",
                                model: {
                                  value: item.municipalRegistration,
                                  callback: function ($$v) {
                                    _vm.$set(item, "municipalRegistration", $$v)
                                  },
                                  expression: "item.municipalRegistration",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  },
                },
                {
                  key: "actions",
                  fn: function ({ item }) {
                    return [
                      _c("div", { staticClass: "actions" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip.top",
                                value: _vm.$t("update"),
                                expression: "$t('update')",
                                modifiers: { top: true },
                              },
                            ],
                            ref: "editCompany",
                            staticClass: "action",
                            on: {
                              click: function ($event) {
                                return _vm.editCompany(item)
                              },
                            },
                          },
                          [
                            _c("i", {
                              staticClass:
                                "icon-r-circle-accept color__secondary",
                            }),
                          ]
                        ),
                      ]),
                    ]
                  },
                },
              ]),
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }