
import Vue from 'vue';
import GroupCardExpandableTable from '@/common/components/groupCard/GroupCardExpandableTable.vue';

export default Vue.extend({
  name: 'GroupCardTable',
  components: {
    GroupCardExpandableTable,
  },
  props: {
    headerLabels: {
      type: Array as () => string[],
      default: [],
    },
    expandableLabels: {
      type: Array as () => {field: string; label: string}[],
      default: () => ([]),
    },
    tableData: {
      type: Array as () => Record<string, unknown>[],
      default: () => ([]),
    },
    expandableItems: {
      type: String as () => string,
      default: '',
    },
    expandablePaginatorLabel: {
      type: String,
      required: true,
    },
    expandableTotal: {
      type: Number as () => number,
      required: true,
    },
    expandableLoading: {
      type: Array as () => boolean[],
      required: true,
    },
  },
  data() {
    return {
      expandLine: [] as boolean[],
      cacheExpandLine: 0 as number,
      openClose: [] as string[],
      innerWidth: 0,
    };
  },
  computed: {
    sortedList(): any[] {
      return this.tableData.slice();
    },
    showScroll(): boolean {
      return this.innerWidth < 1600;
    },
  },
  mounted() {
    window.addEventListener('resize', this.onResize);
  },
  methods: {
    onResize() {
      this.innerWidth = window.innerWidth;
    },
    retractableLine(i: number, item: unknown): void {
      this.$emit('handleExpandableItems', item);
      if (this.cacheExpandLine !== i) {
        this.$set(this.expandLine, this.cacheExpandLine, false);
        this.$set(this.openClose, this.cacheExpandLine, 'plus');
        this.cacheExpandLine = i;
      }

      if (typeof this.expandLine[i] === 'undefined') {
        this.$set(this.expandLine, i, true);
      } else this.$set(this.expandLine, i, !this.expandLine[i]);

      if (this.openClose[i] === 'undefined') {
        this.$set(this.openClose, i, 'less');
      } else {
        this.$set(
          this.openClose,
          i,
          this.openClose[i] === 'less' ? 'plus' : 'less',
        );
      }
    },
  },
});
