

import mixins from 'vue-typed-mixins';
import NotificationMixin from '@/mixins/NotificationMixin';
import CreateGroupGroupDataForm from '@/resources/my-companies/components/CreateGroupGroupDataForm.vue';
import CreateGroupHeadOfficeDataForm from '@/resources/my-companies/components/CreateGroupHeadOfficeDataForms/CreateGroupHeadOfficeDataForm.vue';
import CreateGroupHeadOfficeTable from '@/resources/my-companies/components/CreateGroupHeadOfficeTables/CreateGroupHeadOfficeTable.vue';
import { CompanyInfo } from '../Types';

export type CreateGroupGroupDataFormRef = InstanceType<typeof CreateGroupGroupDataForm>

export default mixins(NotificationMixin).extend({
  name: 'CreateGroupModalContent',
  props: {
    companiesInfo: {
      type: Array as () => CompanyInfo[],
      required: true,
    },
    group: {
      type: Object as () => {name: string; country: string},
      default: () => ({ name: undefined, country: undefined }),
    },
  },
  components: {
    CreateGroupGroupDataForm,
    CreateGroupHeadOfficeDataForm,
    CreateGroupHeadOfficeTable,
  },
  mounted() {
    this.groupData.country = this.group.country === 'BRAZIL' ? 'Brazil' : this.group.country || 'Brazil';
    this.groupData.name = this.group.name || '';
  },
  data() {
    return {
      groupData: {
        country: '',
        name: '',
      },
      headOffice: {
      } as CompanyInfo,
      headOffices: [] as CompanyInfo[],
    };
  },
  watch: {
    headOffices: {
      deep: true,
      handler(data) {
        this.$emit('update:companiesInfo', data);
      },
    },
  },
  methods: {
    addHeadOffice() {
      const headOfficeAlreadyExists = this.headOffices.find(
        (headOffice) => headOffice.nationalRegister === this.headOffice.nationalRegister,
      );
      if (headOfficeAlreadyExists) {
        this.warningToast({
          title: this.$t('warning') as string,
          text: this.$t('myCompanies.modals.createGroup.registerAlreadyExists') as string,
        });
      } else {
        this.headOffices.push(this.headOffice);
        this.headOffice = {} as CompanyInfo;
      }
    },
    removeHeadOffice({ item }: {item: {index: number}}) {
      this.headOffices.splice(item.index, 1);
    },
    clearEstablishments() {
      this.headOffice = {} as CompanyInfo;
      this.headOffices.splice(0);
    },
    async validGroupName() {
      const createGroupForm = this.$refs.createGroupGroupDataForm as CreateGroupGroupDataFormRef;
      const isValid = await createGroupForm.validForm();
      if (isValid) {
        createGroupForm.clearValidations();
      }
      return isValid;
    },
  },
});
