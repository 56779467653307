
import mixins from 'vue-typed-mixins';

import CompanyGroupNotFound from '@/common/components/companyGroup/notice/CompanyGroupNotFound.vue';
import CompanyGroupProcessingWarning from '@/common/components/companyGroup/notice/ProcessingWarning.vue';
import Paginator from '@/common/components/paginator/Paginator.vue';
import CorporateControlService from '@/common/services/corporate-control/CorporateControlService';
import { FindGrouped } from '@/common/services/corporate-control/types';
import PageCard from '@/common/template/PageCard.vue';
import CompanyGroupMixin from '@/mixins/CompanyGroupMixin';
import NotificationMixin from '@/mixins/NotificationMixin';
import CreateGroupModal from '@/resources/my-companies/components/CreateGroupModal.vue';
import GroupCard from '@/resources/my-companies/components/GroupCard/GroupCard.vue';
import TableLoading from '@/resources/my-companies/components/TableLoading.vue';
import HeaderSection from '@/resources/my-companies/sections/HeaderSection.vue';
import { Orientation, PaginationLimitDTO } from '@/types';
import { Establishment } from '@roit-intern/roit-company-interfaces';

export default mixins(NotificationMixin, CompanyGroupMixin).extend({
  name: 'MyCompaniesPage',

  components: {
    PageCard,
    HeaderSection,
    GroupCard,
    Paginator,
    CreateGroupModal,
    TableLoading,
    CompanyGroupProcessingWarning,
    CompanyGroupNotFound,
  },
  data() {
    return {
      groups: [] as FindGrouped[],
      groupsFiltered: [] as FindGrouped[],
      groupsTotal: 0,
      perPage: '10',
      showCompanyGroupModal: false,
      showProcessingWarning: false,
      tableLoading: true,
      isSearchingGroup: false,
    };
  },
  async mounted() {
    await this.goToFirstPage();
  },
  computed: {
    groupsData(): FindGrouped[] {
      return this.isSearchingGroup ? this.groupsFiltered : this.groups;
    },
  },
  methods: {
    changePageSize(value: string): void {
      this.perPage = value;
      this.goToFirstPage();
    },
    async goToLastPage(): Promise<void> {
      await this.getCompanies({
        limit: this.perPage,
        orientation: Orientation.LAST_PAGE,
      });
    },
    async goToFirstPage(): Promise<void> {
      await this.getCompanies({
        limit: this.perPage,
      });
    },
    async nextPage(): Promise<void> {
      const lastGroup = this.groups[this.groups.length - 1];
      const lastCompany = lastGroup.companies[lastGroup.companies.length - 1];
      await this.getCompanies({
        limit: this.perPage,
        cursor: `${lastCompany.companyNationalRegister},${lastCompany.companyGroupId}`,
      });
    },
    async previousPage(): Promise<void> {
      const firstGroup = this.groups[0];
      const firstCompany = firstGroup.companies[0];
      await this.getCompanies({
        limit: this.perPage,
        cursor: `${firstCompany.companyNationalRegister},${firstCompany.companyGroupId}`,
        orientation: Orientation.PREVIOUS_PAGE,
      });
    },
    async getCompanies({
      limit,
      cursor,
      orientation,
    }: PaginationLimitDTO): Promise<void> {
      try {
        this.tableLoading = true;
        const { groups, metadata } = await CorporateControlService.findByMaster({
          limit,
          cursor,
          orientation,
        });
        this.groups = groups.map((group) => ({
          ...group,
          companies: group.companies.map((company) => {
            if (!company.branchs) {
              return {
                ...company,
                branchs: company.hasBranchs ? [{} as Establishment] : [],
              };
            }
            return company;
          }),
        }));

        this.groupsTotal = metadata.total ? metadata.total : this.groupsTotal;
      } catch {
        this.errorToast({
          text: this.$t(
            'myCompanies.notifications.failToGetCompanies',
          ) as string,
        });
      }
      this.tableLoading = false;
    },
    async searchGroup(name: string): Promise<void> {
      try {
        if (name) {
          this.isSearchingGroup = true;
          this.tableLoading = true;
          const { groups } = await CorporateControlService.findByName(name);
          this.groupsFiltered = groups;
        } else {
          this.groupsFiltered = [];
          this.isSearchingGroup = false;
        }
      } catch {
        this.errorToast({
          text: this.$t(
            'myCompanies.notifications.failToGetCompanies',
          ) as string,
        });
      }
      this.tableLoading = false;
    },
  },
});
