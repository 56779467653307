
import Vue from 'vue';
import EditDataInTable from '@/resources/my-companies/components/EditDataInTable.vue';
import InputValidation from '@/common/components/input-validation/InputValidationV2.vue';
import { VForm } from '@/types';

export type EditDataRef = InstanceType<typeof EditDataInTable>

export default Vue.extend({
  name: 'EditDataInTableSelect',
  components: {
    EditDataInTable,
    InputValidation,
  },
  props: {
    label: {
      type: String as () => string,
      required: true,
    },
    rules: {
      type: String as () => string,
      required: false,
      default: 'required',
    },
    select: {
      type: String as () => string,
      required: false,
      default: '',
    },
    options: {
      type: Array as () => Array<{ name: string; value: string }>,
      required: true,
    },
  },
  data() {
    return {
      valueSelected: this.select !== 'notInformed' ? this.select : '',
    };
  },
  methods: {
    async validForm(): Promise<boolean> {
      const form = this.$refs.form as VForm;
      return form.validate();
    },
    async saveChanges(event: Event) {
      if (await this.validForm()) {
        this.$emit('saveChanges', this.valueSelected);
        this.$emit('update:select', this.valueSelected);

        (this.$refs.editData as EditDataRef).toggleOp(event);
      }
    },
  },
});
