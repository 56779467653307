var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "edit-data", on: { click: _vm.toggleOp } },
    [
      _c("i", { staticClass: "icon-r-edit" }),
      _c(
        "OverlayPanel",
        {
          ref: "op",
          staticClass: "op",
          attrs: {
            dismissable: false,
            "append-to": "body",
            "show-close-icon": true,
          },
        },
        [
          _vm._t("default"),
          _c(
            "div",
            { staticClass: "edit-data__container-buttons" },
            [
              _c("Button", {
                staticClass: "outlined",
                attrs: { id: "cancelButton", label: _vm.$t("cancel") },
                on: { click: _vm.toggleOp },
              }),
              _c("Button", {
                staticClass: "raised",
                attrs: { id: "registerButton", label: _vm.$t("save") },
                on: { click: _vm.handleSave },
              }),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }