var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "create-group" },
    [
      _c("ConfirmCreateForeignGroup", {
        attrs: { control: _vm.showConfirmModal, "group-data": _vm.groupData },
        on: {
          "update:control": function ($event) {
            _vm.showConfirmModal = $event
          },
          confirmRegister: _vm.registerGroups,
        },
      }),
      _c(
        "Dialog",
        {
          staticClass: "create-group__modal",
          style: { maxHeight: "none", minWidth: "802px", padding: "0 16px" },
          attrs: {
            visible: _vm.displayModal,
            modal: true,
            "content-style": { overflow: "visible" },
          },
          on: {
            "update:visible": function ($event) {
              _vm.displayModal = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [
                  _c(
                    "h1",
                    { staticClass: "m-0 fs16 family-semi-bold color-black87" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("myCompanies.modals.createGroup.header")
                          ) +
                          " "
                      ),
                    ]
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: "footer",
              fn: function () {
                return [
                  _c("Button", {
                    staticClass: "outlined",
                    attrs: { label: _vm.$t("cancel") },
                    on: {
                      click: function ($event) {
                        _vm.displayModal = false
                      },
                    },
                  }),
                  _c("Button", {
                    attrs: {
                      label: _vm.group.groupId
                        ? _vm.$t("add")
                        : _vm.$t("register"),
                      disabled: _vm.companiesInfo.length === 0,
                    },
                    on: { click: _vm.handleRegisterGroups },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          [
            _c("CreateGroupModalContent", {
              ref: "createGroupModalContent",
              attrs: { "companies-info": _vm.companiesInfo, group: _vm.group },
              on: {
                "update:companiesInfo": function ($event) {
                  _vm.companiesInfo = $event
                },
                "update:companies-info": function ($event) {
                  _vm.companiesInfo = $event
                },
              },
            }),
          ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }