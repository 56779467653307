var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "header" }, [
    _c(
      "div",
      { staticClass: "header__row" },
      [
        _c("h1", { staticClass: "header__title" }, [
          _vm._v(" " + _vm._s(_vm.$t("myCompanies.title")) + " "),
        ]),
        _c("Button", {
          staticClass: "primary",
          attrs: {
            icon: "icon-r-circle-plus",
            label: _vm.$t("myCompanies.newRegister"),
          },
          on: {
            click: function ($event) {
              return _vm.$emit("company:show", true)
            },
          },
        }),
      ],
      1
    ),
    _c("div", { staticClass: "header__row" }, [
      _c(
        "span",
        { staticClass: "p-input-icon-left header__search" },
        [
          _c("i", { staticClass: "icon-r-magnifier-tool grey100" }),
          _c("InputText", {
            attrs: {
              type: "text",
              placeholder: _vm.$t("myCompanies.filterGroupLabel"),
              focus: "",
            },
            on: { input: _vm.onSearch },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }